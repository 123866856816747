$color-primary : #FFC627;
$color-button-click : #f9dd90;
$color-headerbar : #333333;
$color-text : #4F4F4F;
$grey_link_button: #F3F4F6;
$grey_2: #F9FAFB;
$grey_3: #D1D5DB;
$background: #f6f4f3;
$pink: #FEE2E2;
$red: #991B1B;
$blue: #1E40AF;
$light-grey: #6B7280;
$light-blue: #DBEAFE;
$brown: #92400E;
$border_color: #E5E7EB;
$light-grey-background: #F9FAFB;
$body-color: #F3F3F3;
$grey-background: #FEE2E2;
$grey-background-darker: #E9ECEF;
$grey-flow: #9CA3AF;
$green-accept-text: #065F46;
$green-accept: #D1FAE5