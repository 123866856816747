/* You can add global styles to this file, and also import other style files */

@import 'colors';

* {
  box-sizing: border-box;
  margin: 0;

}


body {
  background-color: #f3f4f6;
}

footer {
  z-index: 1;
  width: 100%;
  color: $color-text;
  text-align: center;
  background-color: $body-color;
}

.link {
  text-decoration: none;
}

.buttonCustom {
  border: 1px solid black;
}



.p-button.p-button-label {
  font-weight: 100;
}

.tableCustomHeight.p-datatable{
  .p-datatable-wrapper {
    min-height: 460px;
  }
}

.customTable.p-datatable {
  background: transparent;
  width: auto;


  .p-datatable-header {
    background: transparent;
    border: none;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;

    .table-header {
      background: transparent;
    }
  }

  .p-datatable-wrapper {
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .p-table-custom{
      min-height: 450px;

    }
    .p-datatable-table {

      .p-datatable-thead {
        th {
          padding: 0.6rem 1rem;
          border: none;
          color: $color-text;
          font-weight: 500;
          background-color: $color-primary;

          .p-sortable-column-icon {
            margin-left: 0.3rem;
            vertical-align: baseline;
            font-size: 10pt;
          }

          .p-sortable-column-icon:hover {
            color: $color-headerbar;
            font-weight: 700;
          }
        }

        th::after {
          border: none;
        }
      }

      .tableCell {
        color: $light-grey;
      }
    }

  }

  .p-paginator-bottom.p-paginator {
    .paginator-current {}

    .p-paginator-rpp-options {
      .p-dropdown {
        .p-dropdown-label {}
      }

      display: flex;
      flex:1 1 auto;
      flex-direction:row;
      align-items: center;
      height: 2.1rem;
    }
  }

  .p-column-resizer {
    border-right: 1px dashed #ababab;
    height: 100%;
  }

  .p-resizable-column {}


  .p-paginator {
    background-color: transparent;

  }

  .p-paginator-bottom {
    border: none;
  }

  .p-datatable-wrapper::-webkit-scrollbar {
    margin-top: 0.1rem;
    background-color: transparent;
    height: 0.8rem;
    border-radius: 18px;
    width: 0.5rem;
  }

  //DATEPICKER
  .p-datepicker.p-component {
    max-width: 400px;
    max-height: auto;
    background: white;

    .p-datepicker-group {
      max-width: 400px;
      background: white;

    }

  }

  .p-datatable-wrapper::-webkit-scrollbar-track {
    border-radius: 18px;
  }

  .p-datatable-wrapper::-webkit-scrollbar-thumb {
    background-color: $grey_3;
    //height: 5px;
    max-height: 5px;
    border-radius: 18px;
  }
}

.customButton {
  // background-color: $grey_link_button;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.5rem;
  color: $color-text;
}

.text1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  color: $color-text;
}

.buttonText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  color: $color-text;
}

.text2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  color: $color-text;
}

.badge.p-chip {
  background-color: $pink;
  // color: $red;
  // font-size: 10px;
  height: 1.25rem;
}

p-badge {

  .newMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 0.9rem;
    min-width: 0.9rem;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -75%) !important; //font-size: rem;
    transform-origin: 10% 10%;
    margin: 0;
    padding: 0;
    font-size: 0.5rem;
    background-color: red;
  }
}

p {
  color: $color-text;
  font-family: "Inter", sans-serif;
}
.p-panelcustom.p-panelmenu{
  .p-panelmenu-panel{
    margin-bottom: 1rem;
    .p-toggleable-content{
      .p-panelmenu-content{
     background-color: transparent;
     border: transparent;
        .p-menuitem-link:hover{
          background: rgba(255, 255, 255, 0.26);
          border-radius: 16px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          border: 1px solid rgba(255, 255, 255, 0.3);
        } 
      }
    }
   .p-panelmenu-header{
    .p-panelmenu-header-link{
      background: rgba(255, 255, 255, 0.26);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
    } 
   }
  }

}
//ALBERO

.customTimeline.p-timeline.p-component.p-timeline-left.p-timeline-horizontal {

  .p-timeline-event {
    justify-content: center;
    align-items: center;

    .p-timeline-event-separator {

      //div icona + barretta
      span.border-circle-top.border-circle-bottom {
        //span icona 3
        display: flex;
        padding: 1rem;
        z-index: 1;
        border: 2px solid $color-primary;
        justify-content: center;
        align-items: center;

        .pi-check {
          color: white;
          display: block;
          z-index: 1;
        }

        .p-timeline-event-connector {
          background: blue //barretta
        }

        .p-timeline-event-opposite {}
      }
    }
  }
}

.customTimeline.p-timeline.p-component.p-timeline-left.p-timeline-vertical {
  .p-timeline-event {

    justify-content: center;
    align-items: center;
    height: 50px;

    .p-timeline-event-separator {
      //div icona + barretta

      span.border-circle-top.border-circle-bottom {
        //span icona 3
        display: flex;
        padding: 0.1rem;
        z-index: 1;
        justify-content: center;
        align-items: center;

        .p-timeline-event-connector {
          background-color: black;
        }

        .p-timeline-event-opposite {}
      }

    }
  }
}




.customTimeline.p-timeline.p-component.p-timeline-left.p-timeline-vertical::-webkit-scrollbar {
  background: black;
  max-width: 1px;
}

.p-timeline-vertical .p-timeline-event:last-child {
  height: 50px;
}

.p-timeline-horizontal .p-timeline-event:last-child {
  flex: 1;
}

.myOverlay.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 0rem;
    min-width: auto;
    min-height: auto;

    .p-datatable-tbody {
      .customTable.p-datatable {


        min-height: 400px;
      }
    }
  }
}

.p-ripple.p-element {
  .p-highlight {

    .p-datepicker-current-day {
      background-color: $color-primary;
    }
  }
}


.overlayNotification.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 0;
  }

  max-width: 25vw;
  //z-index: 1001;
  //opacity: 1 !important;
  //transform-origin: center top !important;
  //top: 3.3vh !important;
  --overlayArrowLeft: 100vw !important;
  //transform: translateY(0px) !important;

}

//QUANDO DISABLED
// .p-timeline-horizontal .p-timeline-event {
//     flex-direction: row;
//     flex: 1;
// }
// background: #ffffff;
// color: #495057;


.customCheck.p-checkbox {

  .p-checkbox-box.p-highlight {
    background-color: rgba($color: $color-primary, $alpha: 0.7);
    border-color: rgba($color: $color-primary, $alpha: 0.7);

    .p-checkbox-icon {
      color: black;
      font-size: 0.7rem;
      font-weight: 800;
    }
  }

  // .p-checkbox-box {
  //   max-width: 1.2rem;
  //   max-height: 1.2rem;
  // }

  .p-checkbox-box:hover {
    border-color: $color-primary;

  }
}

.customCheck.p-checkbox:not(.p-checkbox-disabled) {
  .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0.2rem $grey_3;
    border-color: $color-primary;
  }

  .p-checkbox-box:hover {
    border-color: $color-primary;
  }

  .p-checkbox-box.p-highlight:hover {
    background: $color-primary;
    border-color: $color-primary;
    box-shadow: 0 0 0 0.1rem rgba($color: $color-primary, $alpha: 0.4);
  }
}

.customdrop {
  height: 2.9rem;
}

.customAutocomplete.p-autocomplete{
  width: 100%;
  .p-autocomplete-input{
    padding-left: 2rem;
    width: 100%;
  }
}

.p-element.p-dropdown-label.p-inputtext {
  // background-color: #ababab;
  white-space: normal !important;
  word-wrap:inherit !important;
  width: 100%;
  // max-height: 2.8rem;
  text-overflow: ellipsis !important; /* aggiunge i puntini di sospensione quando il testo supera la larghezza massima */

}


.customScroller.p-virtualscroller{
 

  
  .p-virtualscroller-header{
    background-color: transparent;
    
  }
  .p-virtualscroller-content{
    
    //height: 90vh;

    
    .p-scroller-viewport{
      min-height: 57vh !important;
      max-height: 57vh !important;
      .p-virtualscroller-list{
        //contenuto lista 
        //margin-top: 1rem;
        min-height: 57vh !important;
        max-height: 57vh !important;
        //max-height: 48rem !important;
        //border: 1px solid black;
        .p-scroller-content{
          //min-height: inherit;
          //min-height: 30rem !important;
          //padding: 1rem;
          min-height: 57vh !important;
          max-height: 57vh !important;
          //max-height: 48rem !important;          //position: absolute;
          //pannello completo degli elementi ( va in overflow ) 
          .p-virtualscroller-item{
            //singolo item della lista 
            //max-height: 2em;
           //padding-bottom: 2rem;
           //max-height: 3.5rem;
          }
        }
      }
    }
  }
}

//DYNAMIC LIST LAYOUT
.customDropSelector.p-inputwrapper{
  .p-multiselect{
    .p-multiselect-panel{
      .p-multiselect-items-wrapper{
        overflow: scroll;
        max-width: 40rem;
        .p-multiselect-items{
          .p-multiselect-item{
    
              white-space: normal;/* aggiunge i puntini di sospensione quando il testo supera la larghezza massima */
              
            
          }
        }
      }
      .p-multiselect-header{
      }
  }

}



}



